import { render, staticRenderFns } from "./DonateForm.vue?vue&type=template&id=3c5b4057&scoped=true&"
import script from "./DonateForm.vue?vue&type=script&lang=js&"
export * from "./DonateForm.vue?vue&type=script&lang=js&"
import style0 from "./DonateForm.vue?vue&type=style&index=0&id=3c5b4057&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c5b4057",
  null
  
)

export default component.exports